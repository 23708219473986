export const JOB_RESULT_LOADED = "JOB_RESULT_LOADED";
export const JOB_RESULT_LOADING = "JOB_RESULT_LOADING";
export const BALANCES_LOADING = "BALANCES_LOADING";
export const BALANCES_LOADED = "BALANCES_LOADED";
export const JOB_RESULT_INFO_LOADED = "JOB_RESULT_INFO_LOADED";
export const JOB_RESULT_INFO_LOADING = "JOB_RESULT_INFO_LOADING";
export const JOB_RESULT_VIOLATIONS_LOADED = "JOB_RESULT_VIOLATIONS_LOADED";
export const JOB_RESULTS_VIOLATIONS_LOADED = "JOB_RESULTS_VIOLATIONS_LOADED";

export const UPDATE_SHIFT_ASSIGNMENT_START = "UPDATE_SHIFT_ASSIGNMENT_START";
export const UPDATE_SHIFT_ASSIGNMENT_SUCCESS =
  "UPDATE_SHIFT_ASSIGNMENT_SUCCESS";

export const TOGGLE_ABSENCES = "TOGGLE_ABSENCES";
export const TOGGLE_PAGE_HELP = "TOGGLE_PAGE_HELP";
export const TOGGLE_SEND_MAIL = "TOGGLE_SEND_MAIL";

export const EVENTS_LOADED = "EVENTS_LOADED";
export const EVENTS_LOADING = "EVENTS_LOADING";
export const EDIT_EVENT = "EDIT_EVENT";
export const CLOSE_EVENT_MODAL = "CLOSE_EVENT_MODAL";
export const UPDATE_EVENT_START = "UPDATE_EVENT_START";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const EVENT_VALIDATION_ERROR = "EVENT_VALIDATION_ERROR";
export const NEW_EVENT = "NEW_EVENT";
export const CREATE_EVENT_START = "CREATE_EVENT_START";
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";

export const SET_DATES = "SET_DATES";

export const PERIODS_LOADED = "PERIODS_LOADED";
export const PERIODS_LOADING = "PERIODS_LOADING";

export const POSITIONS_LOADED = "POSITIONS_LOADED";
export const POSITIONS_LOADING = "POSITIONS_LOADING";

export const ERROR = "ERROR";

export const PERIOD_WEEKS_LOADED = "PERIOD_WEEKS_LOADED";
export const PERIOD_WEEKS_LOADING = "PERIOD_WEEKS_LOADING";
export const EDIT_PERIOD_WEEK = "EDIT_PERIOD_WEEK";
export const CLOSE_PERIOD_WEEK_MODAL = "CLOSE_PERIOD_WEEK_MODAL";

export const CREATE_PERIOD_WEEK_START = "CREATE_PERIOD_WEEK_START";
export const CREATE_PERIOD_WEEK_SUCCESS = "CREATE_PERIOD_WEEK_SUCCESS";
export const UPDATE_PERIOD_WEEK_START = "UPDATE_PERIOD_WEEK_START";
export const UPDATE_PERIOD_WEEK_SUCCESS = "UPDATE_PERIOD_WEEK_SUCCESS";

export const BUSINESS_MODELS_LOADED = "BUSINESS_MODELS_LOADED";
export const BUSINESS_MODELS_LOADING = "BUSINESS_MODELS_LOADING";

export const EVALUATE_JOB = "EVALUATE_JOB";

export const OPEN_CONFIG_MODAL = "OPEN_CONFIG_MODAL";
export const CLOSE_CONFIG_MODAL = "CLOSE_CONFIG_MODAL";
export const TOGGLE_TEAM = "TOGGLE_TEAM";
export const TOGGLE_ASSIGNMENT_CELL_CONTENT = "TOGGLE_ASSIGNMENT_CELL_CONTENT";

export const UPDATE_VIOLATION = "UPDATE_VIOLATION";
export const UN_UPDATE_VIOLATION = "UN_UPDATE_VIOLATION";
export const TOGGLE_VIOLATIONS = "TOGGLE_VIOLATIONS";
export const TOGGLE_HIGHLIGHT = "TOGGLE_HIGHLIGHT";
export const TOGGLE_VIOLATIONS_WINDOW = "TOGGLE_VIOLATIONS_WINDOW";

export const ADD_ASSIGNMNET_TO_MARKET_SUCCESS =
  "ADD_ASSIGNMNET_TO_MARKET_SUCCESS";
export const ADD_ASSIGNMNET_TO_MARKET_START = "ADD_ASSIGNMNET_TO_MARKET_START";

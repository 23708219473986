import { application } from "./application";

import DesktopNavigationController from "./desktop_navigation_controller";
application.register("desktop-navigation", DesktopNavigationController);

import MobileNavigationController from "./mobile_navigations_controller";
application.register("mobile-navigation", MobileNavigationController);

import TomSelectController from "./tom_select_controller";
application.register("tom-select", TomSelectController);

import BusinessAvailabilityFormController from "./business_availability_form_controller";
application.register(
  "business-availability-form",
  BusinessAvailabilityFormController,
);

import ShiftTypeColorPickerController from "./shift_type_color_picker_controller";
application.register("shift-type-color-picker", ShiftTypeColorPickerController);

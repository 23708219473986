import Appsignal from "@appsignal/javascript";
import { plugin as windowEventsPlugin } from "@appsignal/plugin-window-events";
import { plugin as pathDecoratorPlugin } from "@appsignal/plugin-path-decorator";

const isRunningInJest =
  typeof process !== "undefined" && process.env.JEST_WORKER_ID !== undefined;

function getContentFromMetaTag(tagName) {
  return isRunningInJest
    ? undefined
    : document.head.querySelector(`[name~=${tagName}][content]`).content;
}

const appsignal = new Appsignal({
  ignoreErrors: [/Can't find variable: Chartboost/],
  key: getContentFromMetaTag("appsignal_frontend_push_api_key"),
  revision: getContentFromMetaTag("appsignal_frontend_revision"),
});

appsignal.addDecorator((span) => {
  return span.setTags({
    current_user_id: getContentFromMetaTag(
      "appsignal_frontend_current_user_id",
    ),
  });
});

if (!isRunningInJest) {
  appsignal.use(windowEventsPlugin());
  appsignal.use(pathDecoratorPlugin());
}

export { appsignal };

import pia from "./pia-helper";
import { setCookie, consentSet } from "../helper/gtm-cookies-helper";

const TOGGLING_CHECKBOX_SELECTOR = "input[type='checkbox'][data-toggles]";
const handleTogglingCheckbox = (target) => {
  const checkbox = target;
  const toggles = document.querySelector(checkbox.dataset.toggles);

  if (toggles !== null) {
    toggles.classList.toggle("hidden", !checkbox.checked);
  }
};

document.addEventListener("turbo:load", () => {
  pia.addListener("#support", "click", (e) => {
    e.preventDefault();
    pia.show("#support-expanded");
    pia.hide("#support");
  });

  pia.addListener("#collapse-support", "click", (e) => {
    e.preventDefault();
    pia.hide("#support-expanded");
    pia.show("#support");
  });

  pia.addListener("#page-help-modal", "click", (e) => {
    if (
      e.target.classList.contains("modal-background") ||
      e.target.id == "close_help"
    ) {
      pia.hide("#page-help-modal");
    }
  });
  pia.addListener(".page-help", "click", () => {
    pia.show("#page-help-modal");
  });

  pia.addListener(".sections-desktop .section-dropdown", "click", (e) => {
    e.stopPropagation();
    if (pia.isHidden(".sections-desktop .section-dropdown-expand")) {
      pia.show(".sections-desktop .section-dropdown-expand");
    } else {
      pia.hide(".sections-desktop .section-dropdown-expand");
    }
  });

  pia.addListener(".sections-mobile .section-dropdown", "click", (e) => {
    e.stopPropagation();
    if (pia.isHidden(".sections-mobile .section-dropdown-expand")) {
      pia.show(".sections-mobile .section-dropdown-expand");
    } else {
      pia.hide(".sections-mobile .section-dropdown-expand");
    }
  });
  pia.addListener("body", "click", () => {
    pia.hide(".sections-desktop .section-dropdown-expand");
    pia.hide(".sections-mobile .section-dropdown-expand");
  });

  pia.addListener(TOGGLING_CHECKBOX_SELECTOR, "click", (event) => {
    handleTogglingCheckbox(event.target);
  });

  pia.selectAll(TOGGLING_CHECKBOX_SELECTOR).forEach(handleTogglingCheckbox);

  if (pia.selectFirst(".registrations-new")) {
    // eslint-disable-next-line no-undef
    if (gtmCookieValue && !consentSet()) {
      // eslint-disable-next-line no-undef
      setCookie(gtmCookieValue);
    }
  }
});

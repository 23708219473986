import { Controller } from "@hotwired/stimulus";
import piaHelper from "@/ex_assets/pia-helper";
import { appsignal } from "@/helper/appsignal";

export default class extends Controller {
  static targets = [
    "holdingMenu",
    "mainMenu",
    "navigationFrame",
    "profileMenu",
  ];

  static values = {
    resetContextPath: String,
  };

  connect() {
    // https://turbo.hotwired.dev/reference/events#turbo%3Aframe-missing
    this.navigationFrameTarget.addEventListener(
      "turbo:frame-missing",
      async (event) => {
        event.preventDefault();

        const webserverReply = await event.detail.response.text();
        appsignal.sendError(
          new Error(
            `Unable to load data for mobile navigation Turbo frame: ${webserverReply}`,
          ),
          (span) => {
            span.setTags({
              requestedUrl: event.detail.response.url,
              statusCode: event.detail.response.status,
            });
          },
        );

        event.detail.visit("/");
      },
    );
  }

  toggleHoldingMenu() {
    piaHelper.toggleHidden(this.holdingMenuTarget);

    if (!piaHelper.isHiddenElement(this.holdingMenuTarget)) {
      this.#hideMainMenu();
      this.#hideProfileMenu();
    }
  }

  toggleMainMenu() {
    piaHelper.toggleHidden(this.mainMenuTarget);

    if (!piaHelper.isHiddenElement(this.mainMenuTarget)) {
      this.#hideProfileMenu();
      this.#hideHoldingMenu();
    } else {
      // When setting "src", the Turbo Frame will immediately "navigate" to it
      // However, we can avoid this in case the src is the same as the current one
      if (
        this.navigationFrameTarget.src === null ||
        !this.navigationFrameTarget.src.endsWith(this.resetContextPathValue)
      ) {
        this.navigationFrameTarget.src = this.resetContextPathValue;
      }
    }
  }

  toggleProfileMenu() {
    piaHelper.toggleHidden(this.profileMenuTarget);

    if (!piaHelper.isHiddenElement(this.profileMenuTarget)) {
      this.#hideMainMenu();
      this.#hideHoldingMenu();
    }
  }

  #hideProfileMenu() {
    this.profileMenuTarget.classList.add("hidden");
  }

  #hideMainMenu() {
    this.mainMenuTarget.classList.add("hidden");
  }

  #hideHoldingMenu() {
    if (this.hasHoldingMenuTarget) {
      this.holdingMenuTarget.classList.add("hidden");
    }
  }
}

export const setCookie = (cookieValue) => {
  const date = new Date();
  const expirationInDays = 180;
  date.setTime(date.getTime() + expirationInDays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = `consentcookie=${cookieValue}; ${expires}; path=/`;
};

export const consentSet = () => {
  return (
    consentCookieValue() === "accepted" || consentCookieValue() === "rejected"
  );
};

export const consentCookieValue = () => {
  const name = "consentcookie=";
  return extractCookieValue(name);
};

export const extractCookieValue = (cookieName) => {
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(cookieName) == 0) {
      return c.substring(cookieName.length, c.length);
    }
  }
  return "";
};

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "minDays",
    "maxDays",
    "source",
    "business",
    "teams",
    "teamFields",
  ];

  connect() {
    this.toggle();
    this.selectPositionGroups();
  }

  toggle() {
    const sourceValue = this.sourceTarget.value;
    const minDaysInput = this.minDaysTarget.querySelector("input");

    if (sourceValue === "position_configuration") {
      this.minDaysTarget.style.display = "none";
      this.maxDaysTarget.style.display = "none";
      minDaysInput.disabled = false;
    } else if (sourceValue === "temporary_staff") {
      this.minDaysTarget.style.display = "block";
      this.maxDaysTarget.style.display = "block";
      minDaysInput.value = 0;
      minDaysInput.disabled = true;
    } else {
      this.minDaysTarget.style.display = "block";
      this.maxDaysTarget.style.display = "block";
      minDaysInput.disabled = false;
    }
  }

  selectPositionGroups() {
    if (this.hasBusinessTarget) {
      const selectedBusiness = this.businessTarget.value;
      const positionGroups = this.teamsTarget.options;
      const businessPositionGroupsMapping = JSON.parse(
        this.businessTarget.dataset.businessPositionGroups,
      );

      if (!selectedBusiness) {
        this.teamFieldsTarget.hidden = true;
      } else {
        this.teamFieldsTarget.hidden = false;
      }

      Array.from(positionGroups).forEach((option) => {
        if (selectedBusiness) {
          option.hidden = false;
        } else {
          option.hidden = true;
        }
      });
      if (selectedBusiness) {
        this.filterPositionGroups(
          selectedBusiness,
          businessPositionGroupsMapping,
        );
      }
    }
  }

  filterPositionGroups(selectedBusiness, businessPositionGroupsMapping) {
    Array.from(this.teamsTarget.options).forEach((option) => {
      if (
        option.value &&
        this.hideOption(selectedBusiness, businessPositionGroupsMapping, option)
      ) {
        option.hidden = true;
      }
    });
  }

  hideOption(selectedBusiness, businessPositionGroupsMapping, option) {
    const businessId = parseInt(selectedBusiness);
    const optionValue = parseInt(option.value);
    return !(
      businessPositionGroupsMapping[businessId] &&
      businessPositionGroupsMapping[businessId].includes(optionValue)
    );
  }
}

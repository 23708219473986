import pia from "./pia-helper";
import { extractCookieValue } from "../helper/gtm-cookies-helper";

document.addEventListener("turbo:load", () => {
  pia.addListener(".close-wizard", "click", (e) => {
    const helpText = e.currentTarget.dataset.helpText;

    if (confirm(helpText)) {
      setCookie();
      pia.hide(".wizard-target");
      pia.show(".reopen-wizard");
    }
  });

  pia.addListener(".reopen-wizard", "click", () => {
    expireCookie();
    pia.show(".wizard-target");
    pia.hide(".reopen-wizard");
  });

  if (wizardOpen()) {
    pia.show(".wizard-target");
    pia.hide(".reopen-wizard");
  } else {
    pia.hide(".wizard-target");
    pia.show(".reopen-wizard");
  }
});

const setCookie = () => {
  const date = new Date();
  const expirationInDays = 30;
  date.setTime(date.getTime() + expirationInDays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + date.toUTCString();
  document.cookie = `wizardcookie=closed; ${expires}; path=/`;
};

const expireCookie = () => {
  const date = new Date();
  date.setTime(date.getTime() - 1 * 24 * 60 * 60 * 1000);
  let expires = "expires=" + date.toUTCString();
  document.cookie = `wizardcookie=closed; ${expires}; path=/`;
};

const wizardOpen = () => {
  return wizardHidden() !== "closed";
};

const wizardHidden = () => {
  const name = "wizardcookie=";
  return extractCookieValue(name);
};

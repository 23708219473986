import { ClickOutsideController } from "stimulus-use";
import pia from "@/ex_assets/pia-helper";
import { pickTextColor2 } from "@/helper/helper";

export default class extends ClickOutsideController {
  static targets = ["inputField", "colorPicker"];

  connect() {
    if (this.inputFieldTarget.value !== "") {
      this.inputFieldTarget.style.backgroundColor = this.inputFieldTarget.value;
      this.setTextColor(this.inputFieldTarget.value);
    }
  }

  showColorPicker() {
    pia.showElement(this.colorPickerTarget, "flex");
  }

  selectColor(e) {
    const color = e.target.getAttribute("data-color");
    this.inputFieldTarget.style.backgroundColor = color;
    this.inputFieldTarget.value = color;
    this.setTextColor(color);
    this.hideColorPicker();
  }

  clickOutside() {
    pia.hideElement(this.colorPickerTarget);
  }

  setTextColor(color) {
    this.inputFieldTarget.classList = [pickTextColor2(color)];
  }

  hideColorPicker() {
    pia.hideElement(this.colorPickerTarget);
  }
}
